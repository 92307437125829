import React from "react";
import PatternLayout from "../../components/layouts/pattern-layout";
import ViewportLock from "../../../packages/ViewportLock/src";
import Div from "../../../packages/Div/src";
import Flex from "../../../packages/Flex/src";
import Icon from "../../../packages/Icon/src";
import Container from "../../../packages/Container/src";
import { TabBar } from "../../../packages/Tabs/src";
import { P } from "../../../packages/Typography/src";

var ImmersiveStoryNavigationPattern = function ImmersiveStoryNavigationPattern() {
  return React.createElement(PatternLayout, {
    minimal: true,
    navColor: "white",
    logoOutlined: true
  }, React.createElement(ViewportLock, {
    bg: "grey.900",
    color: "white"
  }, React.createElement(Flex, {
    justifyContent: "center",
    alignItems: "center",
    height: "100%"
  }, React.createElement(Div, {
    textAlign: "center"
  }, "Content")), React.createElement(Div, {
    position: "absolute",
    bottom: "0",
    left: "0",
    right: "0",
    bg: "white",
    boxShadow: "md",
    color: "text"
  }, React.createElement(Container, {
    size: "xs",
    px: ["0", "3rem"]
  }, React.createElement(TabBar, {
    tabProps: {
      height: "4rem",
      width: "25%"
    },
    items: [{
      key: 0,
      render: function render() {
        return React.createElement(Div, null, React.createElement(Icon, {
          name: "update"
        }), React.createElement(P, {
          size: "xxs",
          muted: true,
          mt: "0.25rem",
          mb: "0"
        }, "Updates"));
      }
    }, {
      key: 1,
      render: function render() {
        return React.createElement(Icon, {
          name: "chevron_left"
        });
      }
    }, {
      key: 2,
      render: function render() {
        return React.createElement(Icon, {
          name: "chevron_right"
        });
      }
    }, {
      key: 3,
      render: function render() {
        return React.createElement(Div, null, React.createElement(Icon, {
          name: "share"
        }), React.createElement(P, {
          size: "xxs",
          muted: true,
          mt: "0.25rem",
          mb: "0"
        }, "Share"));
      }
    }]
  })))));
};

export default ImmersiveStoryNavigationPattern;